function readString<T extends string = string>(key: string, options?: T[]): T {
  if (!globalThis.window || process.env["NODE_ENV"] === "test") return "not in browser" as T;

  const localStorageValue = window.localStorage.getItem(key);
  const reactAppValue = process.env[`REACT_APP_${key}`];
  const expressValue = window.EXPRESS_ENV?.[key];
  if (window.EXPRESS_ENV && typeof expressValue !== "string")
    // not supposed to happen, fix index.html
    throw new Error(`Missing window.EXPRESS_ENV.${key} aliasing in index.html. What happened?`);
  const expressValueAliased = expressValue ? expressValue.match(/^%%.*%%$/) === null : false;

  // check for allowed options
  if (options) {
    const explain = `\n\nValue should be one of the strings (${options.join(
      "|",
    )}).\n\nDelete it or change it.`;
    if (expressValueAliased && !options.includes(expressValue as T))
      throw new Error(`window.EXPRESS_ENV.${key} value '${expressValue}' invalid.${explain}`);
    if (typeof reactAppValue === "string" && !options.includes(reactAppValue as T))
      throw new Error(`REACT_APP_${key} value '${reactAppValue}' invalid.${explain}`);
    if (typeof localStorageValue === "string" && !options.includes(localStorageValue as T))
      throw new Error(
        `window.localStorage.getItem('${key}') value '${localStorageValue}' invalid.${explain}\n\nYou can remove it with "window.localStorage.removeItem('${key}')" in the console.`,
      );
  }

  if (typeof reactAppValue === "string") return (localStorageValue ?? reactAppValue) as T;
  if (expressValueAliased) return (localStorageValue ?? expressValue) as T;
  if (key.startsWith("OPTION_") && !expressValueAliased && typeof reactAppValue !== "string") {
    return "" as T;
  }
  throw new Error(
    `REACT_APP_${key} is missing at build time in .env or window.EXPRESS_ENV.${key} is not properly aliased in index.html by Express`,
  );
}

function readBoolean(key: string): boolean {
  return readString(key, ["true", "false"]) === "true";
}

const OIDC_CLIENT_ID = readString("OIDC_CLIENT_ID");
export const MTS_ENV = {
  OIDC_CLIENT_ID,
  OIDC_CLIENT_SECRET: undefined,
  OIDC_AUTHORITY: readString("OIDC_AUTHORITY"),
  COREV2_URL: readString("COREV2_URL"),
  COREV2_ASSETS_URL: readString("COREV2_URL").replace("api/internal", "assets"),
  OPTION_EXTERNAL_LINK_HREF: readString("OPTION_EXTERNAL_LINK_HREF"),
  OPTION_EXTERNAL_LINK_TEXT: readString("OPTION_EXTERNAL_LINK_TEXT"),
  INTEGRATION: readBoolean("INTEGRATION"),
};
